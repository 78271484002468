import AddIp from '@/layouts/Stack/components/AddIp';
import showErrorOpenStackModal from '@/mixins/showErrorOpenStackModal';
import storeMixin from '@/layouts/Stack/mixins/index';
import Vue from 'vue';

export default {
  mixins: [showErrorOpenStackModal, storeMixin],
  data() {
    return {
      device_id: '',
      data: {},
      timerId: '',
      portId: '',
      timerCnt: 0,
      isProcessing: false,
    };
  },
  computed: {},
  methods: {
    addIp: function (instance) {
      // console.log(instance);
      const that = this;
      return new Promise(() => {
        const selfName = 'AddIp';
        this.$modals.open({
          name: selfName,
          size: 'medium',
          component: AddIp,
          closeOnBackdrop: false,
          props: {
            instance: instance,
          },
          text: this.$t('sure.text'),
          on: {
            change: data => {
              instance = data;
              // console.log(instance);
              Vue.set(this.modal.footer.confirm.props, 'disabled', false);
            },
            notready: () => {
              instance = {};
              Vue.set(this.modal.footer.confirm.props, 'disabled', true);
            },
            ready: () => {
              Vue.set(this.modal.footer.confirm.props, 'disabled', false);
            },
          },
          onOpen: inst => (this.modal = inst),
          onClose: () => (this.modal = null),
          onDismiss: () => (this.modal = null),
          footer: {
            confirm: {
              props: { title: this.$t('sure.confirm'), loading: false },
              on: {
                click: () => {
                  Vue.set(this.modal, 'footer', {
                    confirm: {
                      props: { loading: true },
                    },
                  });
                  // обновление порта
                  // console.log(instance);
                  this.updateCurrentServerPort(instance).then(async data => {
                    // console.log(data);
                    if (data) {
                      this.fetchServer();
                      that.$modals.close();
                      this.showResModal('Порт успешно обновлен');
                    } else {
                      this.fetchServer();
                      this.$modals.close({ name: 'AddIp' });
                      // console.log(e);
                      // this.showError(e);
                    }
                  });
                  // } else {
                  //   this.addNewPort(instance)
                  //     .then(async data => {
                  //       this.portId = data.port.id;
                  //       this.addNewPortToServer(this.portId)
                  //         .then(async () => {
                  //           this.fetchServer();
                  //           that.$modals.close();
                  //           this.showResModal('Порт успешно создан');
                  //         })
                  //         .catch(e => {
                  //           console.log(e);
                  //           this.$modals.close();
                  //           if (e.data && e.data.computeFault && e.data.computeFault.message) {
                  //             this.deleteCurrentPort(this.portId).then();
                  //             this.showError(e.data.computeFault.message);
                  //           } else this.showError(e);
                  //         });
                  //     })
                  //     .catch(e => {
                  //       this.$modals.close({ name: 'newPortServer' });
                  //       console.log(e);
                  //       if (e.data && e.data.computeFault && e.data.computeFault.message) {
                  //         this.deleteCurrentPort(this.portId).then();
                  //         this.showError(e.data.computeFault.message);
                  //       } else this.showError(e);
                  //     });
                  // }
                },
              },
            },
            cancel: {
              on: {
                click: () => {
                  that.$modals.close();
                },
              },
            },
          },
        });
      });
    },
    updateCurrentServerPort(payload) {
      // console.log(payload);
      const ips = payload.fixed_ips;
      ips.push(payload.add);
      // console.log(ips);
      // const updateIps = ips.push(payload.add);
      // console.log(updateIps);
      const params = {
        port: {
          fixed_ips: ips,
        },
        id: payload.portId,
        item: 'fixed_ips',
      };
      // console.log(params);
      return this.$store.dispatch('moduleStack/updatePort', params).catch(e => {
        // console.log(e);
        this.showError(e);
      });
    },
    makeModal(props = {}) {
      this.$modals.open({
        name: 'AddModal',
        size: 'small',
        onOpen: inst => (this.modal = inst),
        onClose: () => (this.modal = null),
        onDismiss: () => (this.modal = null),
        ...props,
      });
    },
    showResModal(res, props = {}) {
      if (!this.modal) this.makeModal(props);
      Vue.set(this.modal, 'component', null);
      Vue.set(this.modal, 'closable', true);
      Vue.set(this.modal, 'text', res);
      Vue.set(this.modal, 'footer', {
        confirm: {
          on: {
            click: () => {
              this.$modals.close();
            },
          },
        },
      });
    },
  },
};
