<template>
  <div class="resize">
    <!--    <label class="resize-row">{{ $t('delete') }}</label>-->
    <div class="l-col">
      <base-select
        v-model="address"
        :hide-selected="true"
        select-label=""
        :searchable="false"
        :options="ips"
        :field-label="$t('delete')"
        size="medium"
        :allow-empty="false"
        :preselect-first="true"
        class="resize-row-item"
        @input="onChange"
      >
      </base-select>
    </div>
  </div>
</template>

<script>
import setFocus from '@/mixins/setFocus';
import BaseSelect from '@/components/Select/BaseSelect';
export default {
  name: 'DeleteIp',
  components: { BaseSelect },
  mixins: [setFocus],
  props: {
    port: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      address: [],
      ips: [],
    };
  },
  beforeMount() {
    this.ips = this.port.fixed_ips.map(x => x.ip_address);
  },
  methods: {
    onChange(event) {
      const filtredIps = this.port.fixed_ips.filter(x => x.ip_address !== event);
      const portData = {
        portId: this.port.id,
        address: event,
        fixed_ips: filtredIps,
      };
      this.$emit('port', portData);
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "delete": "Удалить IP-адрес:",
    "sure": {
      "confirm": "Подключить"
    },
    "text": "После сохранения нельзя будет уменьшить размер диска",
    "notice": "Для вступления в силу - перезагрузите сервер"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.resize {
  text-overflow: unset;
  overflow: visible;

  &-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: .75rem .75rem;
&-input {
  padding: 1.5rem 1.5rem;
}
  }
}
</style>
