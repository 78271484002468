<template>
  <div class="port">
    <div class="l-col">
      <div v-if="formData.fixed_ips[0].subnet_id && value.length > 0">
        <!--        <label class="typo__label">{{ $t('port.update') }}</label>-->
        <base-input
          v-model="buferIp"
          class="form__field--input"
          :custom-error-messages="customValidationMsgs ? customValidationMsgs : null"
          use-reactive-validation
          :label="$t('port.update')"
          :pattern="value"
          @input="onChange"
        >
        </base-input>
      </div>
      <div v-if="formData.fixed_ips[0].subnet_id && value.length === 0">
        <label class="typo__label error-color standart-text">{{ $t('label.error') }}</label>
      </div>
    </div>
  </div>
</template>

<script>
import setFocus from '@/mixins/setFocus';
import BaseSelect from '@/components/Select/BaseSelect';
import BaseInput from '@/components/BaseInput/BaseInput.vue';
export default {
  name: 'AddIp',
  components: { BaseInput },
  mixins: [setFocus],
  props: {
    instance: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      customValidationMsgs: {
        confirm: { patternMismatch: this.$t('error') },
      },
      reserveIp: [],
      buferIp: '',
      fullIp: [],
      all: [],
      mask: '',
      pullIp: [],
      newIp: [],
      value: '',
      formData: {
        fixed_ips: [
          {
            subnet_id: '',
            ip_address: '',
          },
        ],
      },
    };
  },
  computed: {
    fixetIps() {
      return this.instance.fixed_ips;
    },
    subnetId() {
      return this.instance.fixed_ips[0].subnet_id;
    },
    allocation_pools() {
      return this.$store.state.moduleStack.subnets.find(x => x.id === this.subnetId)
        .allocation_pools;
    },

    usedPorts() {
      return this.$store.state.moduleStack.ports
        .filter(x => x.network_id === this.instance.network_id)
        .map(x => x.fixed_ips)
        .flat()
        .filter(x => x.subnet_id === this.subnetId)
        .map(x => x.ip_address);
    },
  },
  watch: {
    value: function (event) {
      this.formData.fixed_ips[0].ip_address = event;
      const changedDate = {
        fixed_ips: this.instance.fixed_ips,
        portId: this.instance.id,
        add: this.formData.fixed_ips[0],
      };
      return this.$emit('change', changedDate);
    },
  },
  mounted() {
    this.isReadyNow();
    this.listIp();
  },

  beforeDestroy() {
    this.id = '';
  },
  methods: {
    getAllPorts(start, end) {
      return Array(end - start + 1)
        .fill()
        .map((_, idx) => start + idx);
    },
    listIp() {
      this.mask = this.getMask(this.instance.fixed_ips[0].ip_address);
      this.value = '';
      this.newIp = [];
      this.all = [];
      this.allocation_pools.forEach(x => {
        this.all.push(
          this.getAllPorts(this.getLastIpSection(x.start), this.getLastIpSection(x.end))
        );
      });
      this.all = this.all.flat();
      // console.log(this.all);
      const useIps = this.usedPorts.map(x => this.getLastIpSection(x));
      // console.log(useIps);
      this.newIp = this.all.filter(x => !useIps.includes(x)).flat();

      this.reserveIp = this.getLastIpSection(this.allocation_pools[0].end) + 1;
      this.pullIp = Array.from(new Set(useIps.concat(this.reserveIp))).sort((a, b) => a - b);
      this.pullIp.forEach((x, i) => {
        if (this.pullIp[i + 1] - this.pullIp[i] > 1 && i - 1 !== this.pullIp.length) {
          this.newIp.push(this.pullIp[i] + 1);
        }
      });
      if (this.newIp[0]) this.value = this.mask + this.newIp[0];
      this.buferIp = this.value.length > 0 ? this.value : this.$emit('notready');
      this.pullIp.forEach((x, i) => {
        if (this.pullIp[i + 1] - this.pullIp[i] > 1 && i - 1 !== this.pullIp.length) {
          this.newIp.push(this.pullIp[i] + 1);
        }
      });
    },
    getMask(ip) {
      return ip ? ip.slice(0, ip.lastIndexOf('.') + 1) : null;
    },
    isCorrectIp(ip) {
      if (ip !== this.value) {
        if (this.getMask(ip) !== this.mask) {
          this.$emit('notready');
        } else if (this.pullIp.includes(this.getLastIpSection(ip))) {
          this.$emit('notready');
          this.formData.fixed_ips[0].ip_address = '';
        } else if (isNaN(this.getLastIpSection(ip))) {
          this.formData.fixed_ips[0].ip_address = '';
          this.$emit('notready');
        } else if (
          this.getLastIpSection(this.allocation_pools[0].end) < this.getLastIpSection(ip) ||
          this.getLastIpSection(this.allocation_pools[0].start) > this.getLastIpSection(ip)
        ) {
          this.formData.fixed_ips[0].ip_address = '';
          this.$emit('notready');
        } else {
          this.value = ip;
          this.formData.fixed_ips[0].ip_address = ip;
          this.$emit('ready', {});
        }
      } else {
        this.formData.fixed_ips[0].ip_address = ip;
        this.$emit('ready', {});
      }
    },
    getLastIpSection(ip) {
      return +ip.split('.').at(-1);
    },
    isReadyNow() {
      this.formData.fixed_ips[0].subnet_id = this.instance.fixed_ips[0].subnet_id;
      if (this.formData.fixed_ips[0].subnet_id && this.formData.fixed_ips[0].ip_address) {
        return this.$emit('ready', {});
      } else {
        return this.$emit('notready');
      }
    },
    isIPv4(addr) {
      return /^(([01]?\d{1,2}|2[0-4]\d|25[0-5])(\.|$)){3}$/.test(addr);
    },
    onChange(event) {
      Object.assign(this.formData, event);
      this.isCorrectIp(event);
      // как-нибудь надо переделать
      if (this.formData.fixed_ips[0].subnet_id && this.formData.fixed_ips[0].ip_address) {
      } else return this.$emit('notready');
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "label": {
      "disk": "Источник диска",
      "error": "Свободные порты закончились!",
      "type": "Подсеть:",
      "description": "Описание"
    },
    "error": "Не верный формат",
    "updatePort": "Порт <b>%{msg}</b> будет обновлен",
    "addressesPort": "К порту уже подключены адреса:",
    "port": {
      "label": "IP-адрес:",
      "update": "Добавить новый IP-адрес:"

    },
    "sure": {
      "confirm": "Увеличить"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.port {
  text-overflow: unset;
  overflow: visible;

  &-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1.5rem 1.5rem;
&-input {
  padding: 1.5rem 1.5rem;
}
  }
}
</style>
